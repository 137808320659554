import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CheckOpenedEpisodeQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  episodeId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type CheckOpenedEpisodeQuery = {
  __typename?: 'EntitiesQuery';
  checkOpenedEpisode?: Types.CheckExistingEpisodeStatus | null;
};

export const CheckOpenedEpisodeDocument = `
    query checkOpenedEpisode($patientId: Int!, $episodeId: Int) {
  checkOpenedEpisode(patientId: $patientId, episodeId: $episodeId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkOpenedEpisode: build.query<
      CheckOpenedEpisodeQuery,
      CheckOpenedEpisodeQueryVariables
    >({
      query: (variables) => ({
        document: CheckOpenedEpisodeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckOpenedEpisodeQuery, useLazyCheckOpenedEpisodeQuery } =
  injectedRtkApi;
